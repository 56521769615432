export const CONSTANTS: any = {
    STORAGE: {
        IGREJA_PRINCIPAL: 'i12-primary-church'
    },
    get API_BASE_URL(): string {
        if (window.location.href.includes('localhost')) {
            return 'localhost:8080';
        }
        return 'api.logos.eti.br';
    }
};
